var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("measurementunits.name") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.name,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "name", $$v)
              },
              expression: "listQuery.name"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("measurementunits.enabled"),
                clearable: ""
              },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.enabled,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "enabled", $$v)
                },
                expression: "listQuery.enabled"
              }
            },
            _vm._l(_vm.enableTypeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.value, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.sort,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "sort", $$v)
                },
                expression: "listQuery.sort"
              }
            },
            _vm._l(_vm.sortOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
          ),
          _vm.role[0] !== "admin"
            ? _c(
                "router-link",
                {
                  staticClass: "margin-horizontal",
                  attrs: { to: "/measurement-units/add/" }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "success", icon: "el-icon-edit" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: { width: "50", align: "center", label: "ID", prop: "id" }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "center",
              label: "Name",
              prop: "name"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("enabled", {
                      attrs: { url: "/measurement-units/" + scope.row.id },
                      model: {
                        value: scope.row.enabled,
                        callback: function($$v) {
                          _vm.$set(scope.row, "enabled", $$v)
                        },
                        expression: "scope.row.enabled"
                      }
                    }),
                    _vm._v(" " + _vm._s(scope.row.name) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "center",
              label: "Code",
              prop: "code"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Actions", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: { to: "/measurement-units/edit/" + scope.row.id }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-edit"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }