import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultMeasurementUnitsData = {
  id: 0,
  enabled: true,
  name: '',
  code: ''
};
export var getMeasurementUnits = function getMeasurementUnits(params) {
  return request({
    url: '/measurement-units',
    method: 'get',
    params: params
  });
};
export var getMeasurementUnitsById = function getMeasurementUnitsById(id) {
  return request({
    url: "/measurement-units/".concat(id),
    method: 'get'
  });
};
export var updateMeasurementUnits = function updateMeasurementUnits(id, data) {
  return request({
    url: "/measurement-units/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteMeasurementUnits = function deleteMeasurementUnits(id) {
  return request({
    url: "/measurement-units/".concat(id),
    method: 'delete'
  });
};
export var createMeasurementUnits = function createMeasurementUnits(data) {
  return request({
    url: '/measurement-units/',
    method: 'post',
    data: data
  });
};